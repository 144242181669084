import React from "react";
import { Link } from "react-router-dom";
import * as Utils from "../Utils";

export default function Footer() {
  return (
    <div class="main-con footer-con">
      <div class="container">
        <div class="footer-inner">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-3">
              {/* <a href="index.php"><img class="img-fluid" src="img/logo-white.png" /></a> */}
              <img class="img-fluid" src={require("../assets/img/logo-white.png")} />
            </div>
            <div class="col-lg-6 col-md-6">
              <ul class="footer-links">

                <li><Link class="" to="/about">About</Link></li>
                <li><Link class="" to="/application">Application</Link> </li>
                <li><Link class="" to="/advantage">Advantage</Link> </li>
                <li><Link class="" to="/contact">Contact Us</Link> </li>

              </ul>
            </div>
            <div class="col-lg-3 col-md-3">
              <ul class="social-con">
                <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-con">
        <div class="container">
          <p>&copy;copyright <a href="index.php">Acree Industries LTD. </a>Website designed by <a target="_blank" href="https://rajmith.com">Rajmith</a></p>
        </div>
      </div>
    </div>
  )
}