import React, { useEffect, useState } from 'react'
import Header from "../../layouts/Header";
import Footer from '../../layouts/Footer';
import { get, post } from '../../helper/api_helper';
import { Link, useParams } from 'react-router-dom';

export default function Financialstatement() {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const params = useParams();

    const [list, setList] = useState(undefined);
    const [id, setId] = useState(undefined)
    const [data, setData] = useState(undefined)
    const [title, setTitle] = useState(undefined)

    useEffect(() => {
        if (params?.id) {
            setId(params?.id)

        }
    }, [params?.id])

    useEffect(() => {
        if (id) {
            getDetails()
        }
    }, [id])

    useEffect(() => {
        getData()

    }, [])

    const getData = () => {
        get('category/list')
            .then((res) => {
                if (res?.statusCode == 200) {
                    setList(res?.data)
                    setTitle(res?.data[0]?.title)
                }
            }).catch(err => {
                console.log(err)
            })
    }

    const getDetails = (_id) => {
        let body = {
            catId: id
        }
        post('pdf/details', body)
            .then((res) => {
                if (res?.statusCode == 200) {
                    setData(res?.data)
                }
            }).catch(err => {
                console.log(err)
            })
    }

    console.log('params', params?.id)

    return (
        <div>
            <Header />
            <div class="main-con main-box product-con">
                <div class="container">
                    <h3>{title}</h3>
                    <div class="invest-box">
                        <div class="row">
                            <div class="col-lg-3 col-md-3 text-left">
                                <div class="side-bar">
                                    <ul>
                                        {
                                            list?.map((item, index) => {
                                                return (
                                                    <li key={index}><Link class={id == item?._id ? 'active':''} to={`/financial-statement/${item?._id}`} onClick={() => {setId(item?._id); setTitle(item?.title)}}>{item?.title}</Link></li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-9">
                                <div class="doc-box text-left">
                                    <ul>
                                        {
                                            data?.map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href={item?.pdf} target='_blank'>
                                                            <i class="fa fa-file-pdf-o" aria-hidden="true"></i>{item?.title}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
