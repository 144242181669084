import React, { useEffect } from 'react'
// import React from 'react'
import Header from "../../layouts/Header";
import Footer from '../../layouts/Footer';

export default function Advantage() {


    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div>
            <Header />

            <div class="main-con inner-banner advant-banner">

            </div>

            <div class="main-con main-box product-con">
                <div class="container">
                    <h3>Advantages</h3>

                    <div class="advant-inner advant-page">
                        <div class="row">

                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <img class="img-fluid" src={require("../../assets/images/icons/wind-proof.png")} />
                                {/* <img class="img-fluid" src="img/icons/wind-proof.png" /> */}
                                <h5>Durable & weather proof</h5>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <img class="img-fluid" src={require("../../assets/images/icons/weight-lifting.png")} />
                                {/* <img class="img-fluid" src="img/icons/weight-lifting.png" /> */}
                                <h5>Greater strength & light weight</h5>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <img class="img-fluid" src={require("../../assets/images/icons/speedometer.png")} />
                                {/* <img class="img-fluid" src="img/icons/speedometer.png" /> */}
                                <h5>Hygienic & Highly efficient</h5>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <img class="img-fluid" src={require("../../assets/images/icons/burn.png")} />
                                {/* <img class="img-fluid" src="img/icons/burn.png" /> */}
                                <h5>Fire resistant & Dimensional stability</h5>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <img class="img-fluid" src={require("../../assets/images/icons/adaptation.png")} />
                                {/* <img class="img-fluid" src="img/icons/adaptation.png" /> */}
                                <h5>Adaptability & Economical</h5>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <img class="img-fluid" src={require("../../assets/images/icons/puzzle.png")} />
                                {/* <img class="img-fluid" src="img/icons/puzzle.png" /> */}
                                <h5>Can be join to other material & easy handling</h5>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <img class="img-fluid" src={require("../../assets/images/icons/bacteria.png")} />
                                {/* <img class="img-fluid" src="img/icons/bacteria.png" /> */}
                                <h5>Non toxic & non corrosive & no deposit formation.</h5>
                            </div>

                            <div class="col-lg-4 col-md-4 col-sm-6">
                                <img class="img-fluid" src={require("../../assets/images/icons/water-resistant.png")} />
                                {/* <img class="img-fluid" src="img/icons/water-resistant.png" /> */}
                                <h5>Resistance to soil movement</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div class="main-con main-box app-con">
                <div class="container">
                    <h3>Installation Method</h3>
                    <p>Arcee UPCV pipes are very convenient & simple to install. Arcee pvc pipes can be fixed & laid to the desired length or can be fixed on the size as per requirement. Fix the joins using Solvent cement & provide air valves at regular interval of 300mtrs. length.</p>

                    <ol>
                        <li>Use non return valves forwater raising system.</li>
                        <li>Use coupler ifthe length is too long. it also assist for maintenance.</li>
                        <li>Use proper adapter while fixing with the existing any pipe system.</li>
                        <li>Provide support at joints &at regular distance. Avoid inclined bends.</li>
                        <li>Prefer underground or ducted system to increase life.</li>
                    </ol>
                </div>
            </div>

            <Footer />
        </div>
    )
}
