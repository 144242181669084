import React from "react";
import Home from "../pages/Home";
import About from "../pages/About";
import Advantage from "../pages/Advantage";
import Application from "../pages/Application";
import Connect from "../pages/Connect";
import Contact from "../pages/Contact";
import Financialstatement from "../pages/Financialstatement";
import Product from "../pages/Product"




const publicRoutes = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/advantage", element: <Advantage /> },
  { path: "/application", element: <Application /> },
  { path: "/connect", element: <Connect /> },
  { path: "/contact", element: <Contact /> },
  { path: "/financial-statement/:id", element: <Financialstatement /> },
  { path: "/product", element: <Product /> },

];

export default publicRoutes;