import React from 'react'
import Header from "../../layouts/Header";
import Footer from '../../layouts/Footer';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

function Home() {


    return (
        <div>
            <Header />


            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper">



                <SwiperSlide>  <img class="img-fluid" src={require("../../assets/images/banner.jpg")} /></SwiperSlide>
                <SwiperSlide> <img class="img-fluid" src={require("../../assets/images/banner-two.jpg")} /></SwiperSlide>
                <SwiperSlide> <img class="img-fluid" src={require("../../assets/images/banner-three.jpg")} /></SwiperSlide>

            </Swiper>




            <div class="main-con main-box">
                <div class="container">

                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-7">
                            <div class="about-inner">
                                <h2>ARCEE RIGID PVC PIPES</h2>
                                <p>Established in the year 1989 Arcee is an acknowledged leading producer of UPVC pipes. Arcee Rigid PVC pipes are manufactured in accordance to Bureau of Indian Standards. Confirming to IS-4985 under very strict supervision using suspension grade PVC resin & necessary additives to form the exact compound & then extruded on the most modern pipe plants, thus resulting in the best quality rigid PVC pipes.</p>

                                <Link class="btn-default" to="/about">Read More</Link>

                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5">
                            <div>
                                <img class="img-fluid" src={require("../../assets/images/pipes.png")} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="main-con main-box app-con">
                <h2 className='application_box'>Applications</h2>
                <div class="app-inner">

                    <div class="app-box">
                        <span>
                            <img class="img-fluid" src={require("../../assets/images/icons/tap.png")} />
                        </span>
                        <p>Rural & Urban water distribution system</p>
                    </div>

                    <div class="app-box">
                        <span>
                            <img class="img-fluid" src={require("../../assets/images/icons/tractor.png")} />
                        </span>
                        <p>Agriculture irrigation</p>
                    </div>

                    <div class="app-box">
                        <span>
                            <img class="img-fluid" src={require("../../assets/images/icons/secure-shield.png")} />
                        </span>
                        <p>Protective covering for power & communication cables</p>
                    </div>

                    <div class="app-box">
                        <span>
                            <img class="img-fluid" src={require("../../assets/images/icons/factory.png")} />
                        </span>
                        <p>Industrial use in chemical industries, sugar industries & dairy</p>
                    </div>

                    <div class="app-box">
                        <span>
                            <img class="img-fluid" src={require("../../assets/images/icons/fan.png")} />
                        </span>
                        <p>Air vent system for toxic gas</p>
                    </div>

                    <div class="app-box">
                        <span>
                            <img class="img-fluid" src={require("../../assets/images/icons/pipeline.png")} />
                        </span>
                        <p>Bore well casing & suction pipes</p>
                    </div>

                </div>
                <Link class="btn-default" to="/application">Explore</Link>
            </div>

            <div class="main-con main-box">
                <div class="container">
                    <h2 className='application_box'>Advantages</h2>
                    <div class="advant-inner">
                        <div class="row">

                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <img class="img-fluid" src={require("../../assets/images/icons/wind-proof.png")} />
                                <h5>Durable & weather proof</h5>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <img class="img-fluid" src={require("../../assets/images/icons/weight-lifting.png")} />
                                <h5>Greater strength & light weight</h5>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <img class="img-fluid" src={require("../../assets/images/icons/speedometer.png")} />
                                <h5>Hygienic & Highly efficient</h5>
                            </div>

                            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                <img class="img-fluid" src={require("../../assets/images/icons/burn.png")} />
                                <h5>Fire resistant & Dimensional stability</h5>
                            </div>

                        </div>
                        <div class="text-center">
                            <Link class="btn-default" to="/advantage">Read More</Link>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home
