import React, { useEffect } from 'react'
import Header from "../../layouts/Header";
import Footer from '../../layouts/Footer';

export default function About() {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div>
            <Header />

            <div class="main-con inner-banner about-banner">

            </div>

            <div class="main-con main-box">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-7">
                            <div class="about-inner">
                                <h2>ARCEE RIGID PVC PIPES</h2>
                                <p>Established in the year 1989 Arcee is an acknowledged leading producer of UPVC pipes. Arcee Rigid PVC pipes are manufactured in accordance to Bureau of Indian Standards. Confirming to IS-4985 under very strict supervision using suspension grade PVC resin & necessary additives to from the exact compound & then extruded on the most modern pipe plants, thus resulting in the best quality rigid PVC pipes.</p>
                                <p>Arcee has a well equipped testing laboratory & trained personnel to ensure the Quality is maintained at the highest level.</p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5">
                            <div>
                                <img class="img-fluid" src={require("../../assets/images/pipes.png")} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
