
import React, { useEffect, useRef } from 'react'
import Header from "../../layouts/Header";
import Footer from '../../layouts/Footer';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { post } from '../../helper/api_helper';
import { toast } from 'react-toastify';

export default function Contact() {

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const formRef = useRef()

    const handleValidSubmit = (e, v) => {
        let body = {
            ...v
        }
        post('inquiry',body).
            then((res) => {
                if (res?.statusCode == 200) {
                    toast.success(res?.message)
                    formRef.current.reset()
                }
                if (res?.statusCode == 208) {
                    toast.error(res?.message)
                }

            }).catch(err => {
                console.log('errr', err)
            })
    }

    return (
        <div>
            <Header />
            <div class="main-con inner-banner contact-banner">

            </div>

            <div class="main-con main-box product-con">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-5">
                            <ul class="map-list">
                                <li><i class="fa fa-map-marker" aria-hidden="true"></i>
                                    7th K.M. Barwala Road, <br />Talwandi Rana, Hisar <br /> 125001, India
                                </li>
                                <li><i class="fa fa-mobile" aria-hidden="true"></i>
                                    <a href="tel:9812020111">98120-20111</a>
                                </li>
                                <li><i class="fa fa-phone" aria-hidden="true"></i>
                                    <a href="tel:01662276178">01662-276178</a>
                                </li>
                                <li><i class="fa fa-envelope-o" aria-hidden="true"></i>
                                    <a href="mailto:arceeclub@gmail.com">arceeclub@gmail.com</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-7 col-md-7">
                            <div class="contact-form">
                                <h2>Drop A Line</h2>
                                <AvForm
                                ref={formRef}
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v);
                                    }}>
                                    <div class="form-group">
                                        <AvField type="text" class="form-control" placeholder="Name" name="name" id="name" required />
                                    </div>

                                    <div class="form-group">
                                        <AvField type="text" class="form-control" placeholder="Email" name="email" id="email" required />
                                    </div>

                                    <div class="form-group">
                                        <AvField type="text" class="form-control" placeholder="Mobile" name="mobile" id="mobile_no" required />
                                    </div>

                                    <div class="form-group">
                                        <AvField type="textarea" class="form-control" placeholder="Enter your message" name="msg" id="msg" required />
                                    </div>

                                    <button type="submit" class="btn btn-default">Submit</button>

                                </AvForm>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
