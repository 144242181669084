import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import userAvatar from "../assets/images/logo-icon.png";
import { useSelector } from "react-redux";
import { get } from "../helper/api_helper";

export default function Header(props) {

  const [data, setData] = useState(undefined);
  const [subMenu, setSubMenu] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    get('category/list')
      .then((res) => {
        if (res?.statusCode == 200) {
          setData(res?.data)
        }
      }).catch(err => {
        console.log(err)
      })
  }

  return (
    <div className="header-main ">
      <div class="main-con head-con">
        <div class="top-header">
          <div class="container">
            <div class="top-inner">
              <ul>
                <li><i class="fa fa-phone" aria-hidden="true"></i> Toll Free: <a href="tel:01662276178">01662-276178</a></li>
                <li><i class="fa fa-envelope-o" aria-hidden="true"></i> <a href="mailto:arceeclub@gmail.com">arceeclub@gmail.com</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="main-header">
          <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
              <Link class="navbar-brand" to="/"><img src={require('../assets/images/arcee.png')} /></Link>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><em></em></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item">
                    <Link class="nav-link home" to="/">Home</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link about" to="/about">About</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link products" to="/product">Products</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link application" to="/application">Application</Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link advantage" to="/advantage">Advantage</Link>
                  </li>
                  <li class={subMenu ? 'nav-item dropdown show' : 'nav-item dropdown'} onClick={() => setSubMenu(!subMenu)}>
                    <Link class="nav-link dropdown-toggle invest" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Investor Relations
                    </Link>
                    <div class={subMenu ? 'dropdown-menu show' : 'dropdown-menu'} aria-labelledby="navbarDropdown">

                      {
                        data?.map((item, index) => {
                          return (
                            <Link key={index} class="dropdown-item" to={`/financial-statement/${item?._id}`}>{item?.title}</Link>
                          )
                        })
                      }

                    </div>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link contact" to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}