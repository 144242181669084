export const projectName = "Arcee Industry";
export const themeColor = "#773eff";
// export const API_URL = "http://192.168.121.133:7001/";
export const API_URL = "http://3.110.238.4:7001/";
// export const API_URL = "http://192.168.218.231:8082/";
// export const API_URL = "http://3.108.12.59:8082/";
// export const API_URL = "https://onwardworkspaces.com:8083/";

export const dummySeo = `<title>Onward Workspace</title>
<meta name="description" content="">
<meta name="keywords" content="">
<meta property="og:type" content="website">
<meta property="og:title" content="">
<meta property="og:description" content="">
<meta property="og:url" content="">
<meta property="og:image" content="">
<meta name="robots" content="index, follow">
<link rel="canonical" href="">
<meta name="viewport" content="width=device-width, initial-scale=1.0">`;