import React, { useEffect } from 'react'
// import React from 'react'
import Header from "../../layouts/Header";
import Footer from '../../layouts/Footer';

export default function Application() {
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <div>
            <Header />

            <div class="main-con inner-banner app-banner">

            </div>


            <div class="main-con main-box product-con">
                <div class="container">
                    <h3>Applications</h3>

                    <div class="row justify-content-center">

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/tap-blue.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/tap-blue.png")} />
                                </span>
                                <p>Rural & Urban water distribution system</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/tractor-blue.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/tractor-blue.png")} />
                                </span>
                                <p>Agriculture irrigation</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/secure-shield-blue.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/secure-shield-blue.png")} />
                                </span>
                                <p>Protective covering for power & communication cables</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/factory-blue.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/factory-blue.png")} />
                                </span>
                                <p>Industrial use in chemical industries, sugar industries & dairy</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/fan-blue.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/fan-blue.png")} />
                                </span>
                                <p>Air vent system for toxic gas</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/pipeline-blue.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/pipeline-blue.png")} />
                                </span>
                                <p>Bore well casing & suction pipes</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/waste-blue.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/waste-blue.png")} />
                                </span>
                                <p>Relining of old sewers</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/drainage-blue.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/drainage-blue.png")} />
                                </span>
                                <p>Surface water drainage</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/green-house.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/green-house.png")} />
                                </span>
                                <p>Horticultural & green house technology</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="application-box">
                                <span>
                                    {/* <img class="img-fluid" src="img/icons/pollution.png"> */}
                                    <img class="img-fluid" src={require("../../assets/images/icons/pollution.png")} />
                                </span>
                                <p>Internal plumbing water & waste water supplier domestic & industrial.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>


            <Footer />
        </div>
    )
}
