import React, { useEffect } from 'react'
import Header from "../../layouts/Header";
import Footer from '../../layouts/Footer';

export default function Product() {


    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <div>
            <Header />
            <div class="main-con inner-banner product-banner">

            </div>

            <div class="main-con main-box product-con">
                <div class="container">
                    <h3>PVC Pipes as per is : 4985 : 2000</h3>
                    <div class="product-table">
                        {/* <img class="img-fluid" src="img/products.jpg"> */}
                        <img class="img-fluid" src={require("../../assets/images/products.jpg")} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
